<template>
  <v-card flat :style="`height: ${ windowSize.y + 200 }px; position: 'relative'`" class="pa-1 product-view" v-resize="onResizeHandler">
    <div class="text-center" v-if="loading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-card-text class="pa-0 mt-2">
      <v-form ref="receiveForm" v-on:submit.prevent>
        <v-row class="ma-0">
          <v-col cols="12" class="pb-0 pt-0 font-weight-bold">{{ orderModel.number }} - {{ orderModel.warehouse_name }}</v-col>
          <!-- <v-col cols="12" class="pb-0 pt-0 font-weight-bold">{{ orderModel.warehouse_name }}</v-col> -->
        </v-row>
        <v-row class="ma-0">
          <v-col cols="12" class="pt-0 pr-1 pb-0 mt-1">
            <barcode-scanner v-model="scanProduct" :search="scanProduct" :scannerPayload="scannerPayload"
            :loading="productLoading"></barcode-scanner>
            <!-- <v-text-field outlined dense hide-details v-model="scanProduct" flat autocomplete="off"
              ref="scanProduct" @change="getRespectiveProductOrder" :loading="productLoading"
            :placeholder="$t('message.order.scanProduct')"></v-text-field> -->
          </v-col>
          <v-col cols="12" class="pt-1 pb-1 font-weight-bold" v-if="productFound">
            <span v-if="!isNewProduct">{{ productInfo.productname || '' }}</span>
            <span v-else class="red--text">{{ productInfo.name || '' }}</span>
          </v-col>
          <v-row class="ma-0" v-if="productFound">
            <v-col :cols="productFound && productInfo.isbatch ? '6' : '12'" class="py-0 px-3">
              <!-- <v-text-field outlined dense hide-details v-model="selectedLocation" flat autocomplete="off"
                  :rules="$_requiredValidation" @change="checkIfLocationValid" :loading="loadingLocation"
                  ref="scanLocation" :placeholder="$t('message.product.scanLoc')"></v-text-field> -->
                <v-autocomplete :items="listOfLocations" v-model="selectedLocation" item-text="name" item-value="name"
                hide-details outlined :loading="loadingLocation" :label="$t('message.product.scanLoc')" flat autocomplete="off"
                dense :rules="$_requiredValidation" ref="scanLocation" @change="checkIfLocationValid"></v-autocomplete>
            </v-col>
            <template v-if="productFound && productInfo.isbatch">
              <v-col cols="6" class="py-0 px-2">
                <v-text-field v-if="Math.sign(pickQty) == 1 ? true : false" outlined dense hide-details v-model="selectBatch" flat autocomplete="off"
                :rules="$_requiredValidation" :placeholder="$t('message.product.batch')" ref="batchRef"
                :loading="loadingBatch" @change="checkIfBatchContainsQty"></v-text-field>
                <v-autocomplete v-if="Math.sign(pickQty) == 1 ? false : true" :items="listOfBatchNumbers" v-model="selectBatch" item-text="batchnumberpwa" item-value="batchnumberpwa"
                  hide-details outlined :loading="loadingBatch" :label="$t('message.product.batch')" flat autocomplete="off"
                  dense :rules="$_requiredValidation" ref="batchRef" @change="checkIfBatchContainsQty"></v-autocomplete>
              </v-col>
          </template>
          </v-row>
        </v-row>
        <v-row class="ma-0" v-if="productFound">
          <v-col cols="7" class="pa-1 text-right">
            <v-row class="ma-0">
              <v-col cols="3" class="text-center pa-0" @click="decreaseQty" v-if="isNewProduct">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-minus</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="6" class="pa-0" :class="isNewProduct ? '' : 'ml-5'">
                <v-text-field outlined dense hide-details type="number" class="text-right" v-model="pickQty" :disabled="!isNewProduct"
                  autocomplete="off" ref="qtyRef" :rules="$_norwegianNumberMinusValidation" :placeholder="$t('message.product.qty')"></v-text-field>
              </v-col>
              <v-col cols="3" class="text-center pa-0" @click="increaseQty" v-if="isNewProduct">
                <v-btn color="info" fab x-small>
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5" class="text-center">
            <v-btn color="primary" dark small :loading="saveLoading" @click="receiveQtyOnOrder">
              {{ $t('message.order.adjust') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <!-- LIST OF PRODUCTS -->
    <v-card-text class="highlight pt-0 pb-0 pl-1 mt-2">
      <v-row class="ma-0">
        <v-col cols="6" class="pa-0 pl-3 font-weight-bold">{{ $t('message.whsDashboard.product') }}:</v-col>
        <v-col cols="3" class="pa-0 pr-2 text-center font-weight-bold">{{ $t('message.ccsheet.unit') }}</v-col>
        <v-col cols="3" class="pa-0 text-right font-weight-bold">{{ $t('message.order.ordered') }}</v-col>
      </v-row>
      <v-row class="ma-0">
        <v-col cols="12" class="pa-0">
          <v-divider class="pa-0"></v-divider>
        </v-col>
      </v-row>
      <!-- Load list of products -->
      <v-row class="ma-0" v-for="(product, index) in listOfProducts" :key="index" @click="scanProductByClick(product.productnumber)">
        <v-col cols="6" class="pa-0 pl-3 text-truncate" :title="product.productnumber" :class="{ 'pb-1': (index === listOfProducts.length - 1)}">
          {{ product.productnumber }}
          <br />
          <span>{{ product.productname }}</span>
        </v-col>
        <v-col cols="3" class="pa-0 text-center text-truncate" :title="product.unitname">{{ product.unitname }}</v-col>
        <v-col cols="3" class="pa-0 text-right text-truncate">{{ product.qty | absoluteNumber }}</v-col>
      </v-row>
    </v-card-text>
    <v-btn small color="primary" dark absolute bottom left tabindex="-1" @click="$router.push(`/adjustment_orders/${orderId}`)">
      <v-icon left>mdi-arrow-left-bold</v-icon>{{ $t('message.login.back') }}
    </v-btn>
    <v-btn small color="primary" dark absolute bottom right tabindex="-1" @click="completeDialog = true">
      {{ $t('message.order.complete') }}
    </v-btn>
    <v-dialog v-model="completeDialog" persistent class="mt-0">
      <v-card>
        <v-card-title class="pa-2">
          {{ $t('message.ccsheet.info') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pa-2">
          <h4>{{ $t('message.order.orderComplete') }}</h4>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" small :loading="completeLoading" @click="completeAdjustmentOrder">{{ $t('message.common.yes') }}</v-btn>
          <v-btn color="success" small @click="completeDialog = false">{{ $t('message.common.no') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import moment from 'moment'
export default {
  data () {
    return {
      loading: false,
      orderId: 0,
      listOfProducts: [],
      orderModel: {},
      scanProduct: '',
      windowSize: {
        x: 0,
        y: 0
      },
      productFound: false,
      productInfo: {},
      pickQty: '',
      listOfLocations: [],
      listOfBatchNumbers: [],
      selectedLocation: '',
      selectedLocationId: 0,
      selectBatch: '',
      saveLoading: false,
      loadingBatch: false,
      productLoading: false,
      loadingLocation: false,
      /* Scanner field */
      scannerPayload: {
        placeholder: 'message.order.scanProduct',
        change: this.getRespectiveProductOrder,
        attrRef: 'scannerFieldFocus',
        attrId: 'scannerField'
      },
      receivedProducts: [],
      completeDialog: false,
      completeLoading: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'barcode-scanner': () => import('@/components/BarQrCodeScanner')
  },
  mounted () {
    this.loading = true
    this.orderId = this.$route.params.order_id
    this.getOrderAndProducts()
    /* this.getOrderProducts()
    this.getOrderInfo() */
    this.onResizeHandler()
    this.focusField('scanProduct')
  },
  watch: {
    pickQty (val) {
      // let pickedQty = parseFloat(this.$formatter.replaceCommaWithDot(val || 0))
      // let products = this.$formatter.cloneVariable(this.listOfProducts)
      // let getFoundProd = products.find(x => x.productid === this.productInfo.productid)
      // if (getFoundProd) {
      //   var totalQty = 0
      //   var receivedCount = 0
      //   if (pickedQty) {
      //     totalQty += pickedQty
      //   }
      //   let orderedQty = this.$formatter.replaceCommaWithDot(getFoundProd.qty)
      //   let getOnStockFromLocation = this.listOfLocations.find(x => x.locationid === this.selectedLocationId)
      //   if (parseFloat(orderedQty) < totalQty || (getOnStockFromLocation && getOnStockFromLocation.on_stock < totalQty)) {
      //     totalQty = parseFloat(orderedQty) - parseFloat(receivedCount)
      //     totalQty = getOnStockFromLocation.on_stock < totalQty ? getOnStockFromLocation.on_stock : totalQty
      //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
      //     setTimeout(() => {
      //       this.pickQty = totalQty
      //     })
      //   }
      //   if (this.productInfo.isbatch) this.checkIfBatchContainsQty()
      // }
    }
  },
  methods: {
    getOrderAndProducts () {
      hostAppApi.get(`${this.getHostRefApi}warehouseorders/get_products_by_order/${this.orderId}?user_id=${this.userId}`)
        .then((response) => {
          if (response.data) {
            const { order, products } = response.data
            this.orderModel = order
            this.listOfProducts = []
            if (products) {
              this.receivedProducts = products.filter(x => x.receivedqty)
              this.listOfProducts = products.filter(x => !x.receivedqty)
            }
            this.restorePreset()
            this.productInfo = {}
            this.scanProduct = ''
            this.productFound = false
            this.focusField('scanProduct')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    getOrderInfo () {
      const model = {
        expand: [{
          select: ['name'],
          model: 'Warehouse',
          on_field: 'id',
          on_parent_field: 'warehouseid'
        }],
        filter: `id eq '${this.orderId}'`
      }
      this.$api.execute('post', 'warehouseorders/query', model)
        .then((response) => {
          if (response.data) {
            this.orderModel = response.data[0]
          }
        })
    },
    getAvailableLocationsHandler (product) {
      this.loadingLocation = true
      hostAppApi.get(`${this.getHostRefApi}stocks/get_product_by_warehouse?product_id=${product.id}&warehouse_id=${this.orderModel.warehouseid}&order_type=2&is_all=true`)
        .then((result) => {
          const { locations } = result.data
          this.listOfLocations = locations
          this.loadingLocation = false
          this.selectedLocation = product.locationnamepwa
          this.selectBatch = product.batch_no
          if (this.selectedLocation) {
            this.checkIfLocationValid()
          }
        })
    },
    getOrderProducts () {
      this.loading = true
      this.$api.execute('get', `/warehouseorderitems/get_by_order_pwa/${this.orderId}`)
        .then((response) => {
          this.listOfProducts = []
          if (response.data) {
            const products = response.data
            if (products) {
              this.listOfProducts = products.filter(x => !x.receivedqty)
            }
            this.restorePreset()
            this.productInfo = {}
            this.scanProduct = ''
            this.productFound = false
            this.focusField('scanProduct')
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    async getRespectiveProductOrder () {
      this.selectedLocation = ''
      if (this.scanProduct) {
        this.productLoading = true
        const list = this.$formatter.cloneVariable(this.listOfProducts)
        this.selectedLocationId = 0
        this.isNewProduct = false
        this.productInfo = {}
        if (list) {
          const getProduct = list.find(x => x.barcode === this.scanProduct || x.productnumber === this.scanProduct)
          if (getProduct) {
            this.productFound = true
            this.productInfo = getProduct
            setTimeout(() => {
              this.pickQty = getProduct.qty
              this.selectedLocation = getProduct.locationid
              // this.focusField('scanLocation')
            })
            getProduct.id = getProduct.productid
            this.getAvailableLocationsHandler(getProduct)
          } else {
            this.selectedLocationId = 0
            let product = await this.getProductByNumber()
            if (product && product.data) {
              this.productFound = true
              product = product.data
              product.productid = product.id
              product.name = product.productname
              this.productInfo = product
              this.isNewProduct = true
              this.getAvailableLocationsHandler(product)
            } else {
              this.productInfo = {}
              this.productFound = false
              this.$root.$emit('snackbar', { snackbar: true, color: 'info', text: this.$t('message.product.noProductFound') })
              this.focusField('scanProduct')
              this.scanProduct = ''
            }
          }
          this.restorePreset()
          this.productLoading = false
        }
      }
    },
    receiveQtyOnOrder () {
      const pickedQty = this.$formatter.replaceCommaWithDot(this.pickQty || 0)
      if (this.$refs.receiveForm.validate()) {
        this.saveLoading = true
        const locationid = this.selectedLocationId || 0
        let products = this.$formatter.cloneVariable(this.listOfProducts)
        let model = products.find(x => x.productid === this.productInfo.productid)
        if (!model) model = {}
        if (!model.receivedorders) model.receivedorders = []
        if (!model.adjustorder) model.adjustorder = {}
        /* set product_text */
        model.product_text = this.productInfo.product_text
        /* set location_id & location_text */
        model.location_id = locationid
        model.location_text = this.selectedLocation
        /* --- */
        // if (!model.order_details)
        model.order_details = []
        model.adjustorder.locationid = locationid
        model.locationid = locationid
        model.receivedqty = pickedQty
        model.qty = pickedQty
        model.adjustorder.batchnumberpwa = this.selectBatch || ''
        model.productid = this.productInfo.productid
        model.received_date = model.received_date || moment().format('DD.MM.YYYY')
        /* constructing order_details */
        const receivedOrderObj = {
          received_quantity: pickedQty,
          newly_received: true,
          batch_no: this.selectBatch || '',
          received_date: moment().format('DD.MM.YYYY')/* ,
          serial_number_products: [
            {
              rec_location_id: locationid,
              rec_location_text: this.selectedLocation,
              batch_no: this.selectBatch || ''
            }] */
        }
        if (model.isbatch) model.batch_no = this.selectBatch
        /* ---end--- */
        model.order_details.push(receivedOrderObj)
        products = [...this.receivedProducts, ...products]
        if (this.isNewProduct) {
          model.warehouse_id = this.orderModel.warehouse_id
          model.product_id = model.productid
          products.push(model)
        }
        if (locationid) {
          const modelObj = { items: products }
          hostAppApi.post(`${this.getHostRefApi}warehouseorderitems/bulk_save_pwa/${this.orderId}?user_id=${this.userId}&ordertype=adjustment&new=${this.isNewProduct}&warehouseid=${this.orderModel.warehouseid}`, modelObj)
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('message.common.updatedSuccess') })
              if (response.data && response.data.status === 'received') this.$router.push('/adjustment_orders')
              else this.getOrderAndProducts()
            })
            .finally(() => {
              this.saveLoading = false
            })
        } else {
          this.saveLoading = false
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
        }
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.common.validationIssue') })
      }
    },
    checkIfBatchContainsQty () {
      const pickedQty = parseFloat(this.$formatter.replaceCommaWithDot(this.pickQty || 0))
      if (this.selectBatch && pickedQty < 0) {
        const getObj = this.listOfBatchNumbers.find(x => x.batchnumberpwa === this.selectBatch)
        if (getObj) {
          // if (getObj.onstock < pickedQty) {
          //   this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.order.qtyExceeds' })
          //   setTimeout(() => {
          //     this.pickQty = ''
          //   })
          // }
        } else {
          this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.order.notValidBatch') })
          setTimeout(() => {
            this.selectBatch = ''
          })
        }
      }
    },
    completeAdjustmentOrder () {
      this.completeLoading = true
      hostAppApi.post(`${this.getHostRefApi}warehouseorderitems/complete_adjustment_order/${this.orderId}`)
        .then((response) => {
          this.$root.$emit('snackbar', { snackbar: true, color: 'success', text: this.$t('message.common.updatedSuccess') })
          this.$router.push('/adjustment_orders')
        })
        .finally(() => {
          this.completeLoading = false
        })
    },
    getProductByNumber () {
      return hostAppApi.get(`${this.getHostRefApi}get_product_by_search/${this.scanProduct}`)
      /* const model = {
        filter: `number eq '${this.scanProduct}' OR barcode eq '${this.scanProduct}'`,
        additionalfilters: ['isactive eq 1 AND ispackage ne 1'],
        skip: 0,
        limit: 50,
        order_by: {
          field: 'number',
          asc_or_desc: 'asc'
        }
      }
      return this.$api.execute('post', 'products/query', model) */
    },
    onResizeHandler () {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight - 40 }
    },
    decreaseQty () {
      if (this.pickQty > 0) this.pickQty--
    },
    increaseQty () {
      if (this.pickQty < 0) this.pickQty = ''
      this.pickQty++
    },
    checkIfLocationValid () {
      this.loadingLocation = true
      const locations = this.listOfLocations
      const getOnStockValue = locations.find(x => x.location_name === this.selectedLocation)
      if (getOnStockValue) {
        this.selectedLocationId = getOnStockValue.locationid
        if (this.productInfo.isbatch) this.getBatchNumbersByLocation(this.selectedLocationId)
      } else {
        this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: this.$t('message.product.noLocFound') })
        setTimeout(() => {
          this.selectedLocation = ''
          this.selectedLocationId = 0
          this.focusField('scanLocation')
        })
      }
      this.loadingLocation = false
    },
    getBatchNumbersByLocation (locationid) {
      this.loadingBatch = true
      hostAppApi.post(`${this.getHostRefApi}get_batch_by_location?product_id=${this.productInfo.productid}&location_id=${locationid}&user_id=${this.userId}`)
        .then((result) => {
          const model = result.data
          this.listOfBatchNumbers = model.data
          this.loadingBatch = false
        })
    },
    focusField (value) {
      requestAnimationFrame(() => {
        if (this.$refs[value]) {
          this.$refs[value].focus()
        }
      })
    },
    restorePreset () {
      this.selectBatch = ''
      this.pickQty = ''
      this.selectedLocation = ''
      this.selectedLocationId = 0
    },
    scanProductByClick (productNumber) {
      this.scanProduct = productNumber
      this.getRespectiveProductOrder()
    }
  }
}
</script>
